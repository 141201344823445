<template>
  <div class="flex flex-nowrap text-sm gap-2">
    <es-sheet v-for="(step, i) in steps"
               :key="step.label"
               class="flex w-full flex-grow gap-1 transition-all justify-center items-center max-w-72"
               :class="{ 'text-success': currentStepIndex >= i && step.progress.value === 100}"
    >
      <es-icon :icon="step.icon" class="flex-shrink-0 h-6 w-6" />
      <div class="flex flex-col w-full gap-1 relative">
        <span class="font-bold text-nowrap">{{ i + 1}}. {{ step.label }}</span>
        <span v-if="step.description" class="text-disabled text-xs">{{ step.description }}</span>
        <div :class="currentStepIndex >= i && step.progress.isMultipleValues && step.progress.value !== 100 && step.progress.value !== 0 ? 'opacity-100' : 'opacity-0'" class="absolute -bottom-1 w-full h-1 rounded overflow-hidden bg-primary dark:bg-dark-tertiary">
          <div class="bg-success h-full transition-all" :style="{ 'width': `${step.progress.value}%` }" />
        </div>
        <div class="w-full">
          <slot :name="`step-${step.id}`" />
        </div>
      </div>
    </es-sheet>
  </div>
</template>

<script setup lang="ts">
import {computed} from 'vue'
import {EsSheet, EsIcon} from "@esigndoc/ui";
import {$certificate, $files} from "@/stores/app-store.ts";
import {EsGuideStep} from "@/entities/es-guide-step.ts"
import {useStore} from "@nanostores/vue";
import {useLazyStore} from "@/utils/lazy-store.ts";
import {iCloudUploadOutline, iFileCertificateOutline, iFileSign, iMonitorArrowDownVariant} from "@/entities/icons.ts";

const currentStepIndex = computed(() => {
  const lastIndex = steps.value.findIndex((_) => _.progress.value !== 100)
  return lastIndex >= 0 ? lastIndex : steps.value.length
})

const certificate = useLazyStore($certificate, null)
const files = useStore($files)

const steps = computed(() =>
    [
      new EsGuideStep('certificate', {
        label: 'Выбрать сертификат',
        icon: iFileCertificateOutline,
        progress: {value: certificate.value ? 100 : 0}
      }),
      new EsGuideStep('upload-file', {
        label: 'Загрузить документы',
        icon: iCloudUploadOutline,
        progress: {value: files.value.length ? 100 : 0}
      }),
      new EsGuideStep('set-signature', {
        label: 'Подписать',
        icon: iFileSign,
        progress: {
          value: Math.ceil((files.value.filter(_ => _.isSigned).length / files.value.length) * 100),
          isMultipleValues: true
        }
      }),
      new EsGuideStep('download', {
        label: 'Скачать архив',
        icon: iMonitorArrowDownVariant,
        progress: {value: 100}
      })
    ])

const handleAction = (step: EsGuideStep, i: number) => {
  if (i !== currentStepIndex.value || !step.action) {
    return
  }
  const _ = document.querySelector(`[guide-tag=${step.action.guideTag}]`) as HTMLElement | undefined

  if (!_) {
    throw new Error('Действие для подсказки не найдено')
  }

  _.classList.add('highlight')

  setTimeout(() => {
    _.classList.remove('highlight')
  }, 5000)

  if (step.action.isClick) {
    _.click()
  }
}
</script>
