import type { IconifyIcon } from '@iconify/vue'

export class EsGuideStep {
    id: string
    label: string
    icon?: IconifyIcon
    action?: {
        isClick?: true,
        guideTag: string
    }

    progress: {value: number, isMultipleValues?: boolean} = {
        value: 0,
        isMultipleValues: false
    }
    description?: string

    constructor(id: string, {label, progress, description, icon, action }: Partial<Omit<EsGuideStep, 'id'>> = {}) {
        this.id = id
        this.label = label ?? ''
        this.progress = progress ?? { value: 0 }
        this.description = description
        this.icon = icon
        this.action = action
    }
}
